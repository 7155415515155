light-dropdown-menu {
    .dropdown-button:focus-visible {
        border-radius: 50%;
        outline: $color-dark-blue auto 5px;
    }

    .dropdown-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        width: 30px;
        height: 30px;
    }

    .dropdown-button:hover:not(:focus-visible) {
        border-radius: 50%;
        background-color: $light-grey;
    }
}