.light-domain-page-fix-dashboard {
    .doughnut-width {
        width: 80%;
        max-width: 250px;
    }

    .latest-page-fixes {
        .table-remove-first-border > tbody > tr:first-child td {
            border-top: none;
        }

        img.icon {
            max-height: 45px;
            max-width: 45px;
        }
    }

    .card-header > .card-title {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .category-title {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .page-fix-category-item {
        .progress {
            overflow: visible;
        }

        .progress-bar-monsido-blue,
        .category-statistics {
            transition: all 200ms ease-in-out !important; // Important is necessary because the bootstrap library appends custom transition rules on the style attribute
        }

        &:hover {
            .category-statistics {
                font-size: 14px;
                font-weight: bold;
            }

            .progress-bar-monsido-blue {
                transform: scaleY(1.4);
            }
        }
    }
}
