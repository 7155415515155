domain-card {
    box-sizing: border-box;
    display: inline-block;
    padding: 5px;
    width: 435px;

    .domain-card-wrapper {
        border-radius: 3px;
        background: $color-white;
        box-shadow: 0 0 5px $color-black-transparency-1;

        padding: 20px 0 20px 20px;

        .domain-url {
            margin-top: 5px;
        }

        .row,
        .col {
            margin: 0;
            padding: 0;
        }

        .fa-external-link {
            width: 16px;
        }

        .labels-placeholder {
            min-height: 20px;
        }

        .label-table .btn-label-edit.no-labels {
            margin: 0;
        }

        &:hover {
            box-shadow: 0px 3px 13px $color-black-transparency-1;
        }
    }
}
