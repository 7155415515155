light-domains {
    .page-header {
        margin: 30px 0 0;
        padding-bottom: 0;

        .header-sub {
            margin-bottom: 0;
        }
    }

    .btn-secondary[aria-pressed="true"] {
        background: $main-1;
        color: $color-white;

        .accessibility-mode & {
            background: $color-dark-blue;

            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }

    .monsido-news-placeholder {
        width: 570px;
    }

    .search-wrapper {
        max-width: 350px;
    }
}
