domains-card-view {
    .large-button {
        background: transparent;
        border: 1px dashed $main-1;
        color: $main-1;

        width: 250px;
        height: 66px;

        &:hover,
        &:focus {
            background: $color-white;
        }

        .accessibility-mode & {
            background: $color-white;
            border-color: $color-dark-blue;
            color: $color-dark-blue;

            &:hover {
                background: $secondary-6;
            }
        }
    }

    ul.cards-list {
        padding: 0;

        & > li {
            display: inline-block;
            list-style-type: none;
            padding: 0;
        }
    }
}
